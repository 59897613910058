@import '../_variables.scss';

.sl-spot-list {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  height: $map-page-spot-carousel-height;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;

  .quiver-google-dfp,
  .sl-opt-out,
  .quiver-page-container__footer {
    display: none;
  }
}

.sl-spot-list__spots {
  display: flex;
  margin: 17px 4px 4px 4px;
  padding: 0px;
  white-space: nowrap;
}

.sl-spot-list__ref {
  display: inline-block;
  vertical-align: bottom;
  width: 335px;
  height: 100px;
  margin: 5px 3.5px;

  h4 {
    &:hover,
    &:focus,
    &:active {
      color: $product-body;
    }
  }

  h2 {
    &:hover,
    &:focus,
    &:active {
      color: $product-secondary;
    }
  }
}

.sl-spot-list__ad-place-holder {
  display: none;
}

@media (min-width: $tabletLg) {
  .sl-spot-list {
    height: calc(100vh - $site-header-height-desktop);
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .sl-opt-out {
      display: block;
      margin: 10px 10px 20px 10px;
    }

    .quiver-google-dfp {
      display: block;
      position: absolute;
    }

    .quiver-page-container__footer {
      display: block;
      max-height: 520px;
    }
  }

  .sl-spot-list__spots {
    display: block;
    margin: 17px 10px 10px 10px;
    min-height: calc(100% - 210px);
    padding: 0px;
    position: relative;
    white-space: normal;

    &--no-spots {
      min-height: calc(100% - 600px);
    }
  }

  .sl-spot-list__ref {
    width: 93.6%;
    height: 254px;
  }

  .sl-spot-list__ad-place-holder {
    display: inline-block;
  }

  .sl-spot-list__ref {
    margin: 10px 10px 17px;
  }
}

@media (min-width: $desktop) {
  .sl-spot-list__ref {
    width: 46.5%;
  }

  // Prevent ads from dropping down to a new line.
  .sl-spot-list > .quiver-google-dfp {
    width: 293px;
  }

  .sl-spot-list__spots {
    &--no-spots {
      min-height: calc(100% - 279px);
    }
  }
}

@media (min-width: $x-large-width) {
  .sl-spot-list__ref {
    width: 31.2%;
  }
}
