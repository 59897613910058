.sl-persistent-spot-report-cam {
  // Temporary override for MUI CSS Baseline
  box-sizing: content-box;
  .quiver-cam-player__player__live {
    display: none;
  }
  .quiver-persistent-media--sticky {
    /*
    Hide the video js control bar in the persistent player. Important! We need to do this with
    css instead of a react prop to prevent the player from re-rendering and re-starting. The css
    solution allows the video stream to play smoothly when transitioning between the full and
    persistent cam players.
    */
    .vjs-control-bar {
      display: none;
    }

    .vjs-big-play-button {
      .vjs-icon-placeholder::before {
        height: 50px;
        width: 50px;
      }
    }

    .quiver-cam-player__player__host-ad {
      display: none !important;
    }

    .quiver-cam-rewind,
    .quiver-cam-player__host-ad,
    .jw-controlbar-left-group, // hide timer
    .jw-controlbar-center-group, // hide live broadcast message
    .jwplayer.jw-flag-live .jw-icon, // hide pause button in middle of player (live flag displays loading spinner)
    .jw-icon-fullscreen,
    .jw-icon-volume {
      // hide volume icon
      display: none;
    }

    .jw-icon-fullscreen {
      display: none;
    }

    .jw-background-color {
      // hide gradient behind icon
      background: rgba(0, 0, 0, 0) !important;
    }

    .jwplayer.jw-flag-live .jw-display-icon-display .jw-icon {
      // only show play/pause
      display: inline-block;
    }

    .quiver-cam-error__message,
    .quiver-cam-error__message__text,
    .quiver-cam-error__message__subtext {
      font-size: 12px;
      line-height: 16px;
      svg {
        display: none;
      }
    }
  }

  .quiver-persistent-media--sticky:hover {
    .jw-controls {
      z-index: 2;
    }
  }

  .sl-spot-cam-overlay__close {
    display: none;
  }
}

.sl-persistent-spot-report-cam__overlay {
  height: 100%;
  position: relative;
  width: 100%;
}

.sl-persistent-spot-report-cam:hover .sl-spot-cam-overlay__spot {
  bottom: 5px;
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  z-index: 9;

  .quiver-favorite {
    background: transparent;
    border: none;

    &:hover,
    &:focus-visible {
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }

  .quiver-favorite__item__wrapper {
    line-height: 14px;
    max-width: 220px;
    text-align: left;

    span {
      color: $white;
      display: inline-block;
      font-weight: 600;
      line-height: 14px;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: top;
      white-space: nowrap;
    }
  }
}

.sl-persistent-spot-report-cam:hover .sl-spot-cam-overlay__close {
  display: block;
}

.sl-spot-report-cam__sticky__cam-player {
  margin-top: 16px;
  height: auto;
  background: $black;
}
