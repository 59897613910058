.sl-multi-cam-paywall {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.sl-multi-cam-paywall__content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.sl-multi-cam-paywall__subtitle-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.sl-multi-cam-paywall__title {
  font-family: $product-font-linear;
  font-size: 18px;
  letter-spacing: 0.51px;
  line-height: 21px;
  text-align: center;
  width: 185px;
  margin: 0 0 8px 0;
}

.sl-multi-cam-paywall__subtitle, .sl-multi-cam-paywall__learn-more, .sl-hd-cam {
  display: none;
}


@media (min-width: $tabletLg) {
  .sl-multi-cam-paywall__content {
    justify-content: space-evenly;
    height: 70%;
  }

  .sl-spot-report-page-multi-cam--quad {
    .sl-multi-cam-paywall__content {
      height: 80%;
      margin-bottom: 35px;
    }
  }

  .sl-multi-cam-paywall__subtitle, .sl-multi-cam-paywall__learn-more, .sl-hd-cam {
    display: block;
  }

  .sl-forecast-cta-features__icon {
    > svg {
      height: 47px;
      width: 47px;
    }
  }

  .sl-multi-cam-paywall__title {
    font-size: 32px;
    letter-spacing: 0.9px;
    line-height: 35px;
    width: 335px;
    text-align: center;
  }

  .sl-multi-cam-paywall__subtitle {
    color: color('black');
    font-family: $source-sans-pro;
    margin: 0 0 22px 0;
    font-size: 13px;
    line-height: 1.3;
    letter-spacing: 0.74px;
    text-align: center;
    width: 189.5px;
  }

  .sl-multi-cam-paywall__learn-more {
    color: color('black');
    font-family: $source-sans-pro;
    font-size: 14px;
    letter-spacing: 0.8px;
    text-align: center;
    text-decoration: underline;
  }

  .sl-spot-report-page-multi-cam--quad {
    .sl-multi-cam-paywall__title {
      font-size: 40px;
      line-height: 46px;
      width: 415px;
    }
    .sl-multi-cam-paywall {
      button {
        width: 164px;
        font-size: 16px;
        height: 50px;
        line-height: 49px;
      }
    }
    .sl-multi-cam-paywall__subtitle {
      width: 219.5px;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@media (min-width: $desktop) {
  .sl-spot-report-page-multi-cam--quad {
    .sl-multi-cam-paywall__title {
      font-size: 40px;
      line-height: 46px;
      width: 415px;
    }
    .sl-multi-cam-paywall {
      button {
        width: 164px;
        font-size: 16px;
        height: 50px;
        line-height: 49px;
      }
    }
    .sl-multi-cam-paywall__subtitle {
      width: 219.5px;
      font-size: 16px;
      line-height: 20px;
    }
    .sl-multi-cam-paywall__content {
      height: 70%;
      margin-bottom: 28px;
    }
  }
}

@media (min-width: $tabletLg) and (max-width: $desktop) {
  .sl-spot-report-page-multi-cam--double {
    .sl-multi-cam-paywall__title {
      font-size: 24px;
      width: 415px;
    }
    .sl-multi-cam-paywall {
      button {
        width: 144px;
        font-size: 14px;
        height: 36px;
        line-height: 35px;
      }
    }
  }
}

@media (min-width: $desktopLg) {
  .sl-spot-report-page-multi-cam--double,
  .sl-spot-report-page-multi-cam--triple,
  .sl-spot-report-page-multi-cam--quad {
    .sl-multi-cam-paywall__title {
      font-size: 40px;
      line-height: 46px;
      width: 415px;
    }
    .sl-multi-cam-paywall {
      button {
        width: 164px;
        font-size: 16px;
        height: 50px;
        line-height: 49px;
      }
    }
    .sl-multi-cam-paywall__subtitle {
      width: 219.5px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .sl-spot-report-page-multi-cam--triple,
  .sl-spot-report-page-multi-cam--quad {
    .sl-multi-cam-paywall__content {
      height: 75%;
    }
    .sl-hd-cam {
      height: 34px;
      width: 32px;
    }
  }
  .sl-spot-report-page-multi-cam--quad {
    .sl-multi-cam-paywall__title {
      font-size: 48px;
      line-height: 54px;
      width: 491px;
    }
    .sl-multi-cam-paywall__content {
      height: 75%;
      margin-bottom: 21px;
    }
  }
}
