@import '../_variables.scss';

/* Custom Video.js skin */
@media (orientation: landscape) {
  /* ensure the video is scaled to the height of the screen rather than width in landscape mode.
    This makes sure the video isn't cut off on ultra wide screens */
  .quiver-cam-player--is-fullscreen {
    .quiver-cam-player__player {
      height: 100%;
    }
    .video-js .vjs-tech {
      height: 100vh;
    }
  }
}
.vjs-sl-cam-player,
.vjs-sl-cam-player-minimal {
  background-color: $black;
  color: $white;
  font-family: $product-font-linear;
  font-size: 14px;

  // hide native controls when showing via the context menu when right clicking
  ::-webkit-media-controls {
    display: none !important;
  }

  video {
    outline: none;
  }
}

.vjs-sl-cam-player {
  .vjs-menu {
    .vjs-menu-content {
      font-family: $product-font-linear;
    }
  }

  .vjs-play-progress,
  .vjs-volume-level {
    background-color: $white;
  }

  .vjs-slider-handle {
    background-color: $white;
    border-color: $white;
  }

  .vjs-menu-button-popup ul li {
    background-color: rgba(0, 0, 0, 0.5);
    color: $white;
    font-size: 1em;

    &:hover,
    &:focus-visible {
      background-color: rgba(255, 255, 255, 0.5);
      color: $black;
    }
  }

  .vjs-playback-rate .vjs-playback-rate-value {
    font-size: 1.25em;
    line-height: 2.3;
  }

  .vjs-big-play-button {
    background-color: transparent;
    border-color: transparent;
    color: $white;

    .vjs-icon-placeholder {
      &::before {
        background-image: url('../../../public/images/cam-player/play.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 60px;
        left: 50%;
        opacity: 0.7;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 60px;

        @media (min-width: $tablet) {
          height: 100px;
          width: 100px;
        }

        @media (min-width: $desktop) {
          height: 120px;
          width: 120px;
        }
      }
    }

    &:hover,
    &:focus-visible {
      background-color: transparent;
      border-color: transparent;

      .vjs-icon-placeholder {
        &::before {
          opacity: 0.9;
        }
      }
    }
  }

  &:hover,
  &:focus-visible {
    .vjs-big-play-button {
      background-color: transparent;
      border-color: transparent;

      .vjs-icon-placeholder {
        &::before {
          opacity: 0.9;
        }
      }
    }
  }

  .vjs-loading-spinner {
    border: 0;

    &::before {
      animation: none;
      background-image: url('#{$cdn-logo-path}/surfline-loading-spinner.svg');
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      margin: 0;
    }
  }

  .vjs-control {
    display: none;

    &.vjs-play-control,
    &.vjs-rewind-button,
    &.vjs-fullscreen-control,
    &.vjs-playback-rate,
    &.vjs-remaining-time {
      display: block;
    }

    &.vjs-progress-control {
      display: flex;
    }
  }
}

@mixin minimalControlIcon() {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '' !important;
  display: block;
  height: 50%;
  left: 25%;
  top: 25%;
  opacity: 0.7;
  position: absolute;
  width: 50%;
}

// minimal skin
.video-js.vjs-sl-cam-player-minimal {
  .vjs-control-bar {
    background-color: transparent;
    justify-content: flex-end;
    top: 0;
    padding: 10px;
  }

  .vjs-control {
    border-radius: 8px;
    height: 44px;
    margin-left: 5px;
    max-width: 44px;
    width: 10%;

    &:hover,
    &:focus-visible {
      background-color: rgba(23, 23, 23, 0.5);
    }

    .vjs-icon-placeholder {
      &::before {
        opacity: 0.8;
        top: 2px;
      }
    }

    &.vjs-live-control,
    &.vjs-volume-panel,
    &.vjs-playback-rate,
    &.vjs-remaining-time,
    &.vjs-progress-control {
      display: none;
    }
  }

  .vjs-fullscreen-control,
  .vjs-play-control {
    position: relative;

    .vjs-icon-placeholder::before {
      @include minimalControlIcon();
    }
  }

  .vjs-play-control {
    .vjs-icon-placeholder::before {
      background-image: url('../../../public/images/cam-player/play.svg');
    }

    &.vjs-playing {
      .vjs-icon-placeholder::before {
        background-image: url('../../../public/images/cam-player/pause.svg');
      }
    }
  }

  .vjs-fullscreen-control {
    .vjs-icon-placeholder::before {
      background-image: url('../../../public/images/cam-player/fullscreen.svg');
    }
  }

  &.vjs-fullscreen {
    .vjs-fullscreen-control {
      .vjs-icon-placeholder::before {
        background-image: url('../../../public/images/cam-player/fullscreen-exit.svg');
      }
    }
  }

  .vjs-rewind-button {
    position: relative;

    &::before {
      @include minimalControlIcon();
      background-image: url('../../../public/images/cam-player/cam-rewind.svg');
    }
  }
}

.vjs-sl-cam-player-autoplay {
  .vjs-big-play-button {
    visibility: hidden;
  }
}
